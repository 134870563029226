import React, { useEffect } from "react"

const Accordion = ({ accordion, icon = "?" }) => {
  useEffect(() => {
    // Accordion component
    const accordionEl = document.getElementsByClassName("accordion-title")
    if (accordionEl.length) {
      for (let i = 0; i < accordionEl.length; i++) {
        accordionEl[i].addEventListener("click", function() {
          this.parentNode.classList.toggle("is-open")
          const panel = this.nextElementSibling
          if (panel.style.maxHeight) {
            panel.style.maxHeight = null
          } else {
            panel.style.maxHeight = `${panel.scrollHeight}px`
          }
        })
      }
    }
  }, [])
  return (
    <section className="pricing section">
      <div className="pricing-faqs container-sm">
        <ul className="accordion">
          {accordion.map(item => {
            return (
              <li>
                <div className="accordion-title">
                  <span>
                    <span className="counter">{icon}</span>
                    <span>{item.header}</span>
                  </span>
                  <div className="accordion-icon" />
                </div>
                <div className="accordion-body">
                  <p>{item.content}</p>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Accordion
