import React from "react"
import Layout from "../../components/Layout"
import Accordion from "../../components/Accordion"
import ContentHeader from "../../components/ContentHeader"
import useSuccessfulPurchaseData from "../../static_queries/useSuccessfulPurchaseData"

export default function FAQ() {
  const { frontmatter, html } = useSuccessfulPurchaseData()
  return (
    <Layout b2c={true}  showCta={false} title={frontmatter.title}>

      <div className="html-page" dangerouslySetInnerHTML={{__html: html}} />

      <p className="hero-cta hero-copy hero-text">
        <a className="button button-primary button-shadow" href="https://individuals.livingwisely.org/">Click Here to Get Started</a>
      </p>
    </Layout>
  )
}
